<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <stats-card header-color="indigo">
          <template slot="header">
            <div class="card-icon" @click="menuVisible = !menuVisible">
              <md-icon v-if="!load">coronavirus</md-icon>
              <i class="md-icon md-icon-font md-theme-default" v-if="load">
                <md-progress-spinner
                  :md-diameter="30"
                  :md-stroke="3"
                  md-mode="indeterminate"
                ></md-progress-spinner>
              </i>
            </div>
          </template>
          <template slot="content">
            <md-progress-bar
              v-if="load"
              class="md-accent"
              md-mode="indeterminate"
            ></md-progress-bar>

            <md-app>
              <md-app-toolbar class="md-primary" style="padding: 4px;">
                <span class="md-title">{{ title }}</span>
                <md-button
                  style="margin: 3px 6px;"
                  v-for="dataset in datasets"
                  :key="dataset.id"
                  class="md-white md-raised"
                >
                  <md-checkbox
                    style="margin: 0 16px;"
                    v-model="years"
                    :value="dataset.year"
                    @change="changeYears"
                    >{{ dataset.year }}</md-checkbox
                  >
                </md-button>

                <div class="md-toolbar-section-end">
                  <md-button
                    class="md-icon-button md-primary"
                    @click="toggleMenu"
                    v-if="!menuVisible"
                  >
                    <md-icon>menu</md-icon>
                  </md-button>
                </div>
              </md-app-toolbar>

              <md-app-drawer
                :md-active.sync="menuVisible"
                md-persistent="full"
                md-right
                md-swipeable
              >
                <md-toolbar class="md-transparent" md-elevation="0">
                  <md-button
                    class="md-raised md-success"
                    style="margin: 0 3px; min-height: 48px; width:80%;"
                    @click="generateChart"
                  >
                    <md-icon style="min-width: 28px;">insights</md-icon>
                    Gerar Gráficos
                  </md-button>

                  <div class="md-toolbar-section-end">
                    <md-button
                      style="margin: 0 3px; min-height: 48px;"
                      class="md-icon-button md-dense md-primary"
                      @click="toggleMenu"
                    >
                      <md-icon>keyboard_arrow_left</md-icon>
                    </md-button>
                  </div>
                </md-toolbar>

                <div class="full-control">
                  <div class="list">
                    <md-list>
                      <md-list-item md-expand>
                        <span class="md-list-item-text">Regiões</span>

                        <md-list slot="md-expand">
                          <md-list-item>
                            <md-list style="width: inherit;">
                              <md-list-item
                                md-expand
                                v-for="regionYear in regionsSearched"
                                :key="regionYear.year"
                              >
                                <span class="md-list-item-text">{{
                                  regionYear.year
                                }}</span>

                                <md-list slot="md-expand">
                                  <md-table
                                    v-model="regionYear.data"
                                    md-sort="countCases"
                                    md-sort-order="desc"
                                    md-card
                                  >
                                    <md-table-toolbar>
                                      <md-field
                                        md-clearable
                                        class="md-toolbar-section-start"
                                      >
                                        <md-input
                                          placeholder="Busca..."
                                          v-model="regionsSearch"
                                          @input="
                                            searchOnRegion(
                                              regionsSearched,
                                              regions,
                                              regionsSearch
                                            )
                                          "
                                        />
                                      </md-field>
                                    </md-table-toolbar>

                                    <md-table-row
                                      slot="md-table-row"
                                      slot-scope="{ item }"
                                    >
                                      <md-table-cell
                                        ><md-checkbox
                                          v-model="regionsSelected"
                                          :value="item"
                                          @change="getRegionCases(item)"
                                        >
                                        </md-checkbox>
                                      </md-table-cell>

                                      <md-table-cell
                                        md-label="Nome"
                                        md-sort-by="name"
                                        >{{ item.name }}</md-table-cell
                                      >
                                      <md-table-cell
                                        md-label="Total"
                                        md-sort-by="countCases"
                                        >{{ item.countCases }}</md-table-cell
                                      >
                                    </md-table-row>
                                  </md-table>
                                </md-list>
                              </md-list-item>
                            </md-list>
                          </md-list-item>
                        </md-list>
                      </md-list-item>

                      <md-list-item md-expand>
                        <span class="md-list-item-text">Estados</span>

                        <md-list slot="md-expand">
                          <md-list-item>
                            <md-list style="width: inherit;">
                              <md-list-item
                                md-expand
                                v-for="stateYear in statesSearched"
                                :key="stateYear.year"
                              >
                                <span class="md-list-item-text">{{
                                  stateYear.year
                                }}</span>

                                <md-list slot="md-expand">
                                  <md-table
                                    v-model="stateYear.data"
                                    md-sort="countCases"
                                    md-sort-order="desc"
                                    md-card
                                  >
                                    <md-table-toolbar>
                                      <md-field
                                        md-clearable
                                        class="md-toolbar-section-start"
                                      >
                                        <md-input
                                          placeholder="Busca..."
                                          v-model="statesSearch"
                                          @input="
                                            searchOnRegion(
                                              statesSearched,
                                              states,
                                              statesSearch
                                            )
                                          "
                                        />
                                      </md-field>
                                    </md-table-toolbar>

                                    <md-table-row
                                      slot="md-table-row"
                                      slot-scope="{ item }"
                                    >
                                      <md-table-cell
                                        ><md-checkbox
                                          v-model="statesSelected"
                                          :value="item"
                                          @change="getStateCases(item)"
                                        >
                                        </md-checkbox>
                                      </md-table-cell>

                                      <md-table-cell
                                        md-label="Nome"
                                        md-sort-by="name"
                                        >{{ item.name }}</md-table-cell
                                      >
                                      <md-table-cell
                                        md-label="Total"
                                        md-sort-by="countCases"
                                        >{{ item.countCases }}</md-table-cell
                                      >
                                    </md-table-row>
                                  </md-table>
                                </md-list>
                              </md-list-item>
                            </md-list>
                          </md-list-item>
                        </md-list>
                      </md-list-item>

                      <md-list-item md-expand>
                        <span class="md-list-item-text">Capitais</span>

                        <md-list slot="md-expand">
                          <md-list-item>
                            <md-list style="width: inherit;">
                              <md-list-item
                                md-expand
                                v-for="stateYear in capitalsSearched"
                                :key="stateYear.year"
                              >
                                <span class="md-list-item-text">{{
                                  stateYear.year
                                }}</span>

                                <md-list slot="md-expand">
                                  <md-table
                                    v-model="stateYear.data"
                                    md-sort="capital.countCases"
                                    md-sort-order="desc"
                                    md-card
                                  >
                                    <md-table-toolbar>
                                      <md-field
                                        md-clearable
                                        class="md-toolbar-section-start"
                                      >
                                        <md-input
                                          placeholder="Busca..."
                                          v-model="capitalsSearch"
                                          @input="
                                            searchOnCapital(
                                              capitalsSearched,
                                              states,
                                              capitalsSearch
                                            )
                                          "
                                        />
                                      </md-field>
                                    </md-table-toolbar>

                                    <md-table-row
                                      slot="md-table-row"
                                      slot-scope="{ item }"
                                    >
                                      <md-table-cell
                                        ><md-checkbox
                                          v-model="capitalsSelected"
                                          :value="item"
                                          @change="getCapitalCases(item)"
                                        >
                                        </md-checkbox>
                                      </md-table-cell>

                                      <md-table-cell
                                        md-label="Nome"
                                        md-sort-by="capital.name"
                                        >{{ item.capital.name }}</md-table-cell
                                      >
                                      <md-table-cell
                                        md-label="Total"
                                        md-sort-by="capital.countCases"
                                        >{{
                                          item.capital.countCases
                                        }}</md-table-cell
                                      >
                                    </md-table-row>
                                  </md-table>
                                </md-list>
                              </md-list-item>
                            </md-list>
                          </md-list-item>
                        </md-list>
                      </md-list-item>

                      <md-list-item
                        md-expand
                        v-for="citiesInState in citiesSearched"
                        :key="citiesInState.id"
                      >
                        <span class="md-list-item-text">
                          Cidades do {{ citiesInState.initials }}
                        </span>

                        <md-list slot="md-expand">
                          <md-list-item
                            v-for="citiesYear in citiesInState.years"
                            :key="citiesYear.year"
                          >
                            <md-list style="width: inherit;">
                              <md-list-item md-expand>
                                <span class="md-list-item-text">{{
                                  citiesYear.year
                                }}</span>

                                <md-list slot="md-expand">
                                  <md-table
                                    v-model="citiesYear.data"
                                    md-sort="countCases"
                                    md-sort-order="desc"
                                    md-card
                                  >
                                    <md-table-toolbar>
                                      <md-field
                                        md-clearable
                                        class="md-toolbar-section-start"
                                      >
                                        <md-input
                                          placeholder="Busca..."
                                          v-model="citiesSearch"
                                          @input="
                                            searchOnCity(
                                              citiesSearched,
                                              citiesInStates,
                                              citiesSearch
                                            )
                                          "
                                        />
                                      </md-field>
                                    </md-table-toolbar>

                                    <md-table-row
                                      slot="md-table-row"
                                      slot-scope="{ item }"
                                    >
                                      <md-table-cell
                                        ><md-checkbox
                                          v-model="citiesSelected"
                                          :value="item"
                                          @change="getCityCases(item)"
                                        >
                                        </md-checkbox>
                                      </md-table-cell>

                                      <md-table-cell
                                        md-label="Nome"
                                        md-sort-by="name"
                                        >{{ item.name }}</md-table-cell
                                      >
                                      <md-table-cell
                                        md-label="Total"
                                        md-sort-by="countCases"
                                        >{{ item.countCases }}</md-table-cell
                                      >
                                    </md-table-row>
                                  </md-table>
                                </md-list>
                              </md-list-item>
                            </md-list>
                          </md-list-item>
                        </md-list>
                      </md-list-item>

                      <md-list-item
                        md-expand
                        v-for="(mesoregionsInState,
                        index) in mesoregionsSearched"
                        :key="index"
                      >
                        <span class="md-list-item-text">
                          Mesorregião do {{ mesoregionsInState.initials }}
                        </span>

                        <md-list slot="md-expand">
                          <md-list-item
                            v-for="mesoregionsYear in mesoregionsInState.years"
                            :key="mesoregionsYear.year"
                          >
                            <md-list style="width: inherit;">
                              <md-list-item md-expand>
                                <span class="md-list-item-text">{{
                                  mesoregionsYear.year
                                }}</span>

                                <md-list slot="md-expand">
                                  <md-table
                                    v-model="mesoregionsYear.data"
                                    md-sort="countCases"
                                    md-sort-order="desc"
                                    md-card
                                  >
                                    <md-table-toolbar>
                                      <md-field
                                        md-clearable
                                        class="md-toolbar-section-start"
                                      >
                                        <md-input
                                          placeholder="Busca..."
                                          v-model="mesoregionsSearch"
                                          @input="
                                            searchOnCity(
                                              mesoregionsSearched,
                                              mesoregionsInStates,
                                              mesoregionsSearch
                                            )
                                          "
                                        />
                                      </md-field>
                                    </md-table-toolbar>

                                    <md-table-row
                                      slot="md-table-row"
                                      slot-scope="{ item }"
                                    >
                                      <md-table-cell
                                        ><md-checkbox
                                          v-model="mesoregionsSelected"
                                          :value="item"
                                          @change="getMesoregionCases(item)"
                                        >
                                        </md-checkbox>
                                      </md-table-cell>

                                      <md-table-cell
                                        md-label="Nome"
                                        md-sort-by="name"
                                        >{{ item.name }}</md-table-cell
                                      >
                                      <md-table-cell
                                        md-label="Total"
                                        md-sort-by="countCases"
                                        >{{ item.countCases }}</md-table-cell
                                      >
                                    </md-table-row>
                                  </md-table>
                                </md-list>
                              </md-list-item>
                            </md-list>
                          </md-list-item>
                        </md-list>
                      </md-list-item>

                      <md-list-item
                        md-expand
                        v-for="microregionsInState in microregionsSearched"
                        :key="microregionsInState.ibge_id"
                      >
                        <span class="md-list-item-text">
                          Microrregião do {{ microregionsInState.initials }}
                        </span>

                        <md-list slot="md-expand">
                          <md-list-item
                            v-for="microregionsYear in microregionsInState.years"
                            :key="microregionsYear.year"
                          >
                            <md-list style="width: inherit;">
                              <md-list-item md-expand>
                                <span class="md-list-item-text">{{
                                  microregionsYear.year
                                }}</span>

                                <md-list slot="md-expand">
                                  <md-table
                                    v-model="microregionsYear.data"
                                    md-sort="countCases"
                                    md-sort-order="desc"
                                    md-card
                                  >
                                    <md-table-toolbar>
                                      <md-field
                                        md-clearable
                                        class="md-toolbar-section-start"
                                      >
                                        <md-input
                                          placeholder="Busca..."
                                          v-model="microregionsSearch"
                                          @input="
                                            searchOnCity(
                                              microregionsSearched,
                                              microregionsInStates,
                                              microregionsSearch
                                            )
                                          "
                                        />
                                      </md-field>
                                    </md-table-toolbar>

                                    <md-table-row
                                      slot="md-table-row"
                                      slot-scope="{ item }"
                                    >
                                      <md-table-cell
                                        ><md-checkbox
                                          v-model="microregionsSelected"
                                          :value="item"
                                          @change="getMicroregionCases(item)"
                                        >
                                        </md-checkbox>
                                      </md-table-cell>

                                      <md-table-cell
                                        md-label="Nome"
                                        md-sort-by="name"
                                        >{{ item.name }}</md-table-cell
                                      >
                                      <md-table-cell
                                        md-label="Total"
                                        md-sort-by="countCases"
                                        >{{ item.countCases }}</md-table-cell
                                      >
                                    </md-table-row>
                                  </md-table>
                                </md-list>
                              </md-list-item>
                            </md-list>
                          </md-list-item>
                        </md-list>
                      </md-list-item>
                    </md-list>
                  </div>
                </div>
              </md-app-drawer>

              <md-app-content>
                <div id="chart"></div>
              </md-app-content>
            </md-app>
          </template>

          <template slot="footer">
            <div class="stats"></div>
          </template>
        </stats-card>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { StatsCard } from "@/components";
import ApexCharts from "apexcharts";
import moment from "moment-mini";

export default {
  components: {
    StatsCard
  },
  async created() {
    this.load = true;
    this.source = this.$route.params.source;
    this.datasetName = this.$route.params.datasetName;
    this.type = this.$route.params.type;
    this.filter = this.$route.params.filter;
    await this.getDataSets();
    await this.getRegions();
    await this.getStates();
    await this.getCities();
    await this.getMesoregions();
    await this.getMicroregions();
    this.load = false;
  },
  async mounted() {
    let type = "";
    let filter = "";

    if (this.type == "cases") {
      type = "Casos de";
    } else if (this.type == "deaths") {
      type = "Óbitos por";
    }

    if (this.filter == "per_date") {
      filter = "por dia";
    } else if (this.filter == "incidence") {
      filter = "incidência por dia";
    } else if (this.filter == "moving_agv") {
      filter = "média móvel por dia";
    } else if (this.filter == "incidence_moving_agv") {
      filter = "média móvel de incidência por dia";
    }

    this.chartOptions.yaxis.title.text = `${type} ${this.datasetName.toUpperCase()} ${filter} Fonte: ${this.source.toUpperCase()}`;
    this.title = this.chartOptions.yaxis.title.text;

    this.chart = new ApexCharts(
      document.querySelector("#chart"),
      this.chartOptions
    );
    await this.chart.render();
  },
  data() {
    return {
      title: "",
      datasetName: "",
      source: "",
      datasetsSelected: [],
      load: false,
      years: [],
      chart: null,
      regions: [],
      regionsSelected: [],
      regionsSeries: [],
      regionsSearched: [],
      regionsSearch: "",
      states: [],
      statesSelected: [],
      statesSeries: [],
      statesSearched: [],
      statesSearch: "",
      capitalsSelected: [],
      capitalsSeries: [],
      capitalsSearched: [],
      capitalsSearch: "",
      citiesInStates: [],
      citiesSelected: [],
      citiesSeries: [],
      citiesSearched: [],
      citiesSearch: "",
      mesoregionsInStates: [],
      mesoregionsSelected: [],
      mesoregionsSeries: [],
      mesoregionsSearched: [],
      mesoregionsSearch: "",
      microregionsInStates: [],
      microregionsSelected: [],
      microregionsSeries: [],
      microregionsSearched: [],
      microregionsSearch: "",
      menuVisible: true,
      expandNews: false,
      expandSingle: false,
      datasets: [],
      series: [],
      chartOptions: {
        series: [],
        chart: { height: 500, type: "line", stacked: false },
        annotations: { points: [] },
        colors: [
          "#d63031",
          "#0984e3",
          "#00b894",
          "#fdcb6e",
          "#e84393",
          "#6c5ce7",
          "#00cec9",
          "#e17055",
          "#fd79a8",
          "#a29bfe",
          "#81ecec",
          "#fab1a0",
          "#ff7675",
          "#74b9ff",
          "#55efc4",
          "#ffeaa7"
        ],
        dataLabels: { enabled: false },
        stroke: { width: [], curve: "smooth" },
        markers: {
          size: 2,
          strokeOpacity: 0.8,
          strokeWidth: 0.5,
          fillOpacity: 0.8
        },
        fill: {
          opacity: [],
          gradient: {
            inverseColors: false,
            shade: "light",
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100]
          }
        },
        yaxis: {
          labels: {},
          title: {
            text: ""
          }
        },
        xaxis: {
          type: "datetime",
          labels: {
            hideOverlappingLabels: true,
            showDuplicates: true,
            format: "dd-MM",
            datetimeFormatter: {
              year: "yyyy",
              month: "dd-MM",
              day: "dd-MM",
              hour: "HH:mm"
            }
          }
        },
        title: {
          text: "",
          align: "right",
          margin: 0,
          offsetX: 0,
          offsetY: 25,
          floating: false,
          style: {
            fontSize: "10px",
            fontWeight: "bold",
            fontFamily: undefined,
            color: "#858796"
          }
        },
        tooltip: { x: { format: "dd/MM" } }
      }
    };
  },
  props: {},
  methods: {
    async changeYears() {
      this.load = true;
      await this.getRegions();
      await this.getStates();
      await this.getCities();
      await this.getMesoregions();
      await this.getMicroregions();
      this.load = false;
    },
    async generateChart() {
      this.chart.destroy();
      this.chartOptions.series = [];
      this.chartOptions.title.text = `S3BioTech: ${moment(
        String(this.datasets[0].updated_at)
      ).format("DD/MM/YYYY")}`;

      let items = [
        {
          selecteds: this.regionsSelected,
          series: this.regionsSeries
        },
        {
          selecteds: this.statesSelected,
          series: this.statesSeries
        },
        {
          selecteds: this.citiesSelected,
          series: this.citiesSeries
        },
        {
          selecteds: this.mesoregionsSelected,
          series: this.mesoregionsSeries
        },
        {
          selecteds: this.microregionsSelected,
          series: this.microregionsSeries
        }
      ];

      await items.forEach(item => {
        item.selecteds.forEach(selected => {
          item.series[selected.year].forEach(serie => {
            if (selected.id == serie.id) {
              this.chartOptions.series.push(serie.data);
            }
          });
        });
      });

      await this.capitalsSelected.forEach(capitalSelected => {
        this.capitalsSeries[capitalSelected.year].forEach(serie => {
          if (capitalSelected.capital.id == serie.id) {
            this.chartOptions.series.push(serie.data);
          }
        });
      });

      this.chart = new ApexCharts(
        document.querySelector("#chart"),
        this.chartOptions
      );

      this.chart.render();
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    async getDataSets() {
      try {
        let result = await Vue.$http.get(
          `v1/dataset/${this.source}/${this.datasetName}?limit=5`
        );
        this.datasets = await result.data;
        await this.years.push(await this.datasets[0].year);
        await this.datasetsSelected.push(await this.datasets[0]);
      } catch (e) {
        await this.$store.dispatch("alerts/error", e);
      }
    },
    async getRegions() {
      this.regions = [];
      this.regionsSearched = [];
      this.regionsSelected = [];
      this.regionsSeries = [];

      this.years.forEach(async year => {
        this.regionsSeries[year] = [];
        try {
          let result = await Vue.$http.get(
            `v1/region/${this.source}/${this.datasetName}/${year}?type=${this.type}`
          );
          this.regions.push({ year: year, data: result.data });
          this.regionsSearched.push({ year: year, data: result.data });
        } catch (e) {
          await this.$store.dispatch("alerts/error", e);
        }
      });
    },
    async getStates() {
      this.states = [];
      this.statesSearched = [];
      this.statesSelected = [];
      this.statesSeries = [];
      this.capitalsSelected = [];
      this.capitalsSeries = [];
      this.capitalsSearched = [];

      this.years.forEach(async year => {
        this.statesSeries[year] = [];
        this.capitalsSeries[year] = [];

        try {
          let result = await Vue.$http.get(
            `v1/state/${this.source}/${this.datasetName}/${year}?type=${this.type}`
          );
          this.states.push({ year: year, data: result.data });
          this.statesSearched.push({ year: year, data: result.data });
          this.capitalsSearched.push({ year: year, data: result.data });
        } catch (e) {
          await this.$store.dispatch("alerts/error", e);
        }
      });
    },
    async getCities() {
      this.citiesInStates = [];
      this.citiesSearched = [];
      this.citiesSelected = [];
      this.citiesSeries = [];

      await this.years.forEach(async year => {
        this.citiesSeries[year] = [];
        try {
          let result = await Vue.$http.get(
            `v1/city/${this.source}/${this.datasetName}/${year}?type=${this.type}`
          );

          await result.data.forEach(async state => {
            let index = null;

            for (let i = 0; i < this.citiesInStates.length; i++) {
              if (state.id == this.citiesInStates[i].id) {
                index = i;
                break;
              }
            }

            if (index == null) {
              this.citiesInStates.push({
                id: state.id,
                name: state.name,
                initials: state.initials,
                years: [
                  {
                    id: state.id,
                    year: year,
                    data: state.cities
                  }
                ]
              });
              this.citiesSearched.push({
                id: state.id,
                name: state.name,
                initials: state.initials,
                years: [
                  {
                    id: state.id,
                    year: year,
                    data: state.cities
                  }
                ]
              });
            } else {
              this.citiesInStates[index].years.push({
                id: state.id,
                year: year,
                data: state.cities
              });
              this.citiesSearched[index].years.push({
                id: state.id,
                year: year,
                data: state.cities
              });
            }
          });
        } catch (e) {
          await this.$store.dispatch("alerts/error", e);
        }
      });
    },
    async getMesoregions() {
      this.mesoregionsInStates = [];
      this.mesoregionsSearched = [];
      this.mesoregionsSelected = [];
      this.mesoregionsSeries = [];
      await this.years.forEach(async year => {
        this.mesoregionsSeries[year] = [];
        try {
          let result = await Vue.$http.get(
            `v1/mesoregion/${this.source}/${this.datasetName}/${year}?type=${this.type}`
          );
          await result.data.forEach(async state => {
            let index = null;
            for (let i = 0; i < this.mesoregionsInStates.length; i++) {
              if (state.id == this.mesoregionsInStates[i].id) {
                index = i;
                break;
              }
            }
            if (index == null) {
              this.mesoregionsInStates.push({
                id: state.id,
                name: state.name,
                initials: state.initials,
                years: [
                  {
                    id: state.id,
                    year: year,
                    data: state.mesoregions
                  }
                ]
              });
              this.mesoregionsSearched.push({
                id: state.id,
                name: state.name,
                initials: state.initials,
                years: [{ id: state.id, year: year, data: state.mesoregions }]
              });
            } else {
              this.mesoregionsInStates[index].years.push({
                id: state.id,
                year: year,
                data: state.mesoregions
              });
              this.mesoregionsSearched[index].years.push({
                id: state.id,
                year: year,
                data: state.mesoregions
              });
            }
          });
        } catch (e) {
          await this.$store.dispatch("alerts/error", e);
        }
      });
    },
    async getMicroregions() {
      this.microregionsInStates = [];
      this.microregionsSearched = [];
      this.microregionsSelected = [];
      this.microregionsSeries = [];
      await this.years.forEach(async year => {
        this.microregionsSeries[year] = [];
        try {
          let result = await Vue.$http.get(
            `v1/microregion/${this.source}/${this.datasetName}/${year}?type=${this.type}`
          );
          await result.data.forEach(async state => {
            let index = null;
            for (let i = 0; i < this.microregionsInStates.length; i++) {
              if (state.id == this.microregionsInStates[i].id) {
                index = i;
                break;
              }
            }
            if (index == null) {
              this.microregionsInStates.push({
                id: state.id,
                name: state.name,
                initials: state.initials,
                years: [{ id: state.id, year: year, data: state.microregions }]
              });
              this.microregionsSearched.push({
                id: state.id,
                name: state.name,
                initials: state.initials,
                years: [{ id: state.id, year: year, data: state.microregions }]
              });
            } else {
              this.microregionsInStates[index].years.push({
                id: state.id,
                year: year,
                data: state.microregions
              });
              this.microregionsSearched[index].years.push({
                id: state.id,
                year: year,
                data: state.microregions
              });
            }
          });
        } catch (e) {
          await this.$store.dispatch("alerts/error", e);
        }
      });
    },
    async getRegionCases(item) {
      this.load = true;
      let isCache = false;
      this.years.forEach(async year => {
        this.regionsSeries[year].forEach(region => {
          if (region.id == item.id && year == item.year) {
            isCache = true;
            this.load = false;
          }
        });
      });

      if (!isCache) {
        try {
          let result = await Vue.$http.get(
            `v1/region/${this.source}/${this.datasetName}/${item.year}?type=${this.type}&${this.filter}=${item.id}`
          );
          let serie = result.data.serie;
          serie.name = `${serie.name} ${item.year}`;

          this.regionsSeries[item.year].push({
            id: item.id,
            data: serie
          });
          this.load = false;
        } catch (e) {
          await this.$store.dispatch("alerts/error", e);
        }
      }
    },
    async getStateCases(item) {
      this.load = true;
      let isCache = false;
      this.years.forEach(async year => {
        this.statesSeries[year].forEach(state => {
          if (state.id == item.id && year == item.year) {
            isCache = true;
            this.load = false;
          }
        });
      });
      if (!isCache) {
        try {
          let result = await Vue.$http.get(
            `v1/state/${this.source}/${this.datasetName}/${item.year}?type=${this.type}&${this.filter}=${item.id}`
          );
          let serie = result.data.serie;
          serie.name = `Estado: ${serie.name} ${item.year}`;
          this.statesSeries[item.year].push({ id: item.id, data: serie });
          this.load = false;
        } catch (e) {
          await this.$store.dispatch("alerts/error", e);
        }
      }
    },
    async getCapitalCases(item) {
      this.load = true;
      let isCache = false;
      this.years.forEach(async year => {
        this.capitalsSeries[year].forEach(capital => {
          if (capital.id == item.capital.id && year == item.year) {
            isCache = true;
            this.load = false;
          }
        });
      });
      if (!isCache) {
        try {
          let result = await Vue.$http.get(
            `v1/city/${this.source}/${this.datasetName}/${item.year}?type=${this.type}&${this.filter}=${item.capital.id}`
          );
          let serie = result.data.serie;
          serie.name = `Capital: ${serie.name} ${item.year}`;
          this.capitalsSeries[item.year].push({
            id: item.capital.id,
            data: serie
          });
          this.load = false;
        } catch (e) {
          await this.$store.dispatch("alerts/error", e);
        }
      }
    },
    async getCityCases(item) {
      this.load = true;
      let isCache = false;
      this.years.forEach(async year => {
        this.citiesSeries[year].forEach(city => {
          if (city.id == item.id && year == item.year) {
            isCache = true;
            this.load = false;
          }
        });
      });
      if (!isCache) {
        try {
          let result = await Vue.$http.get(
            `v1/city/${this.source}/${this.datasetName}/${item.year}?type=${this.type}&${this.filter}=${item.id}`
          );
          let serie = result.data.serie;
          serie.name = `Cidade: ${serie.name} ${item.year}`;
          this.citiesSeries[item.year].push({ id: item.id, data: serie });
          this.load = false;
        } catch (e) {
          await this.$store.dispatch("alerts/error", e);
        }
      }
    },
    async getMesoregionCases(item) {
      this.load = true;
      let isCache = false;
      this.years.forEach(async year => {
        this.mesoregionsSeries[year].forEach(mesoregion => {
          if (mesoregion.id == item.id && year == item.year) {
            isCache = true;
            this.load = false;
          }
        });
      });
      if (!isCache) {
        try {
          let result = await Vue.$http.get(
            `v1/mesoregion/${this.source}/${this.datasetName}/${item.year}?type=${this.type}&${this.filter}=${item.id}`
          );
          let serie = result.data.serie;
          serie.name = `Mesoregião: ${serie.name} ${item.year}`;
          this.mesoregionsSeries[item.year].push({ id: item.id, data: serie });
          this.load = false;
        } catch (e) {
          await this.$store.dispatch("alerts/error", e);
        }
      }
    },
    async getMicroregionCases(item) {
      this.load = true;
      let isCache = false;
      this.years.forEach(async year => {
        this.microregionsSeries[year].forEach(microregion => {
          if (microregion.id == item.id && year == item.year) {
            isCache = true;
            this.load = false;
          }
        });
      });
      if (!isCache) {
        try {
          let result = await Vue.$http.get(
            `v1/microregion/${this.source}/${this.datasetName}/${item.year}?type=${this.type}&${this.filter}=${item.id}`
          );
          let serie = result.data.serie;
          serie.name = `Microrregião: ${serie.name} ${item.year}`;
          this.microregionsSeries[item.year].push({ id: item.id, data: serie });
          this.load = false;
        } catch (e) {
          await this.$store.dispatch("alerts/error", e);
        }
      }
    },
    toLower(text) {
      text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      return text.toString().toLowerCase();
    },
    searchByName(items, term) {
      if (term) {
        items = items.filter(item => {
          return this.toLower(item.name).includes(this.toLower(term));
        });
      }
      return items;
    },
    searchOnRegion(searcheds, items, search) {
      searcheds.forEach(searched => {
        items.forEach(item => {
          if (item.year == searched.year) {
            searched.data = this.searchByName(item.data, search);
          }
        });
      });
    },
    searchByCapitalName(items, term) {
      if (term) {
        items = items.filter(item => {
          return this.toLower(item.capital.name).includes(this.toLower(term));
        });
      }
      return items;
    },
    searchOnCapital(searcheds, items, search) {
      searcheds.forEach(searched => {
        items.forEach(item => {
          if (item.year == searched.year) {
            searched.data = this.searchByCapitalName(item.data, search);
          }
        });
      });
    },
    searchOnCity(searcheds, items, search) {
      searcheds.forEach(searched => {
        items.forEach(item => {
          if (item.id == searched.id) {
            searched.years.forEach(searchedYear => {
              item.years.forEach(itemYear => {
                if (itemYear.year == searchedYear.year) {
                  searchedYear.data = this.searchByName(itemYear.data, search);
                }
              });
            });
          }
        });
      });
    }
  }
};
</script>
<style lang="scss">
.md-drawer {
  max-width: 360px;
}
ul div.md-card {
  margin: 0;
}

.md-checkbox {
  margin: 0 16px !important;
}

.container-checkbox {
  background-color: #ffffff;
}

.md-table.md-theme-default .md-table-head {
  padding: 5px;
  margin: 5px;
}

.md-table.md-theme-default .md-table-head:first-child {
  max-width: 35px;
  padding: 0;
  margin: 5px;
}

.md-table.md-theme-default .md-table-row td:first-child {
  max-width: 35px;
  padding: 0;
  margin: 5px;
}
</style>
